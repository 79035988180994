export const CLIENTS_CONFIG = [
    {
        name: "app",
        url: "https://app.ctrading.io",
        email: "info@ctrading.io",
        emailName: "CTrading",
        domain: "ctrading.io",
        useMatomo: true,
        matomoId: 6,
        matomoCookieDomain: ".app.ctrading.io",
        useTrading: true,
        useArbitrage: true,
        useReferrals: true,
        needToApproveUsers: false,
        usePremiumModel: true,
        isDemo: true,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: "",
        usePostHog: true
    }
]
